html {
	height: 100%;
	/* ↓ Sets font size to base 10 */
	font-size: 62.5%;
}
body {
	// height: 100%;
	/* ↓ Sets font size to 16 px if browser font size is 16px & 14 px if browser font size is 14 px */
	/* This gives you base 10 rems & ems that behave as normal */
	font-size: 1.4em;
	padding: 1em;
	background-image: url("../../img/book__cover__image--bitcoin-code.jpg");
	background-attachment: fixed;
	background-position: top left;
	background-size: cover;
	color: #000;
	font-family: 'Helvetica Neue', 'Helvetica', 'Calibri', 'Arial', sans-serif;
	// font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	line-height: 1.3em !important;
}
h1 {
	font-size: 3.157em !important;;
}
h2 {
	font-size: 2.369em !important;;
}
h3 {
	font-size: 1.777em !important;;
}
hr {
	border: 0;
	border-top: #ddd 1px solid;
}
a.blue, a.blue:link, a.blue:visited, a.blue:hover, a.blue:active {
	color: #0095C6;
	text-decoration: none;
}
a.unl, a.unl:link, a.unl:visited, a.unl:hover, a.unl:active {
	text-decoration: none;
}
.bookindex a, .bookindex a:link, .bookindex a:visited, .bookindex a:hover, .bookindex a:active {
	color: #0095C6;
	text-decoration: none;
}
.main-wrapper {
	margin: 0 auto;
	max-width: 50em;
	background-color: #fff;
	box-shadow: 5px 5px 50px #222;
}
header {
	min-height: 16em;
	background-image: url("../../img/book__cover__image--cave.jpg");
	background-size: cover;
	background-position: center;
	color: #fff;
}
header h1 {
	text-align: center;
	font-weight: 500;
	margin-top: 1em;
	text-shadow: #222 3px 5px 5px;
	font-family: 'Cinzel', serif;
}
header h3 {
	display: none;
	font-family: 'Lusitana', serif;
}
main  {
	padding: 0.8em 2.2em;
	line-height: 1.3em;
	// white-space: pre;
	// white-space: pre-wrap;
	// white-space: pre-line;
	// white-space: -pre-wrap;
	// white-space: -o-pre-wrap;
	// white-space: -moz-pre-wrap;
	// white-space: -hp-pre-wrap;
	// word-wrap: break-word;
}
main nav {
	text-align: center;
}
main nav p {
	padding-top: 0.6em;
}
main nav p a {
	padding: 5px 7px;
	border: #0095c6 1px solid;
	border-radius: 3px;
}
main nav p a.active {
	background: #0095C6;
	border-radius: 3px;
	color: #fff;
}
main .page-nav {
	text-align: center;
}
main .page-nav p a {
	padding: 5px 7px;
}
main .page-nav p a:hover {
	background: #0095C6;
	border-radius: 3px;
	color: #fff !important;
}

iframe.trailerVideo {
	width: 100%;
	height: 20em;
}

.strike a, a .strike {
	text-decoration: line-through;
	color: #666;
}
.btn2.btn2-main, a.btn2.btn2-main {
	text-align: center;
	display: block;
	margin: auto;
	max-width: 14em;
	border: 1px #aaa solid;
	border-radius: 0.3em;
	background: #fff;
	padding: 0.7em 0.3em;
	font-size: 1.3em;
}
.btn2.btn2-main:hover, a.btn2.btn2-main:hover {
	background-color: #FAFAFA;
	-webkit-box-shadow: inset 0 0 6px #999;
	box-shadow: inset 0 0 6px #999;
}
// .download-btns {
// 	text-align: center;
// }
// .download-btns .btn {
// 	margin-bottom: 1em;
// }
// .download-btns .btn a {
// 	display: block;
// 	margin: auto;
// 	max-width: 8em;
// 	border: 1px #aaa solid;
// 	border-radius: 0.3em;
// 	background: #fff;
// 	padding: 0.7em 0.3em;
// 	font-size: 1.3em;
// 	font-size: 1.3rem;
// }
// .download-btns .btn a:hover {
// 	background-color: #FAFAFA;
// 	-webkit-box-shadow: inset 0 0 6px #999;
// 	box-shadow: inset 0 0 6px #999;
// }
// .download-btns .btn.strike a {
// 	text-decoration: line-through;
// 	color: #666;
// }
// .support {
// 	text-align: center;
// }
// .support .qr-img img {
// 	display: block;
// 	margin: 0 auto;
// 	width: 100%;
// 	max-width: 16em;
// }
// #qrcode-btn-btc {
// 	width: 1.3em;
// 	vertical-align: -0.25em;
// }
// .book-cover, .cover-img .img-wrap, .author .img-wrap {
// 	padding: 0 4em;
// }
// .book .img-wrap {
// 	padding: 0 7em;
// }
img.coffee-softcover.home {
	max-width: 100%;
	width: 26em;
	display: block;
	margin: 0 auto;
}
.support {
	margin: 0 auto;
	max-width: 20em;
}
.book-cover img, .book img, .cover-img img, .author img {
	max-width: 100%;
}
.book h3, .cover-img h3, .author h3 {
	border-bottom: 1px #ddd solid;
	padding-bottom: 0.2em;
}

.media-item {
	display: block;
	margin: 1em auto;
	width: 30em;
	height: 16em;
}

.img-grid-text {
	padding: 0.5em;
}

.book-style-quote {
	// font-size: 1em;
	font-weight: bolder;
}

@media only screen and (min-width: 25em) {
	header h1 {
		margin-top: 1.2em;
	}
	header h3 {
		text-align: center;
		font-weight: 500;
		text-shadow: #222 3px 4px 4px;
		color: #eee;
		display: block;
	}
	main h1, main h2, main h3, main h4, main h5, main h6 {
		line-height: 1em;
	}
}
@media only screen and (min-width: 34em) {
	// .table {
	// 	display: table;
	// 	width: 100%;
	// 	height: 11em;
	// }
	// .table-row {
	// 	display: table-row;
	// }
	// .table-cell {
	// 	display: table-cell;
	// }
}
@media only screen and (min-width: 42em) {
	body {
		padding: 2.5em;
	}
}
@media only screen and (min-width: 80em) {
	.main-wrapper {
		max-width: 60em;
	}
}
